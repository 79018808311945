<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <div class="flex">
        <div class="container container__dashboard">
          <div class="menu-home">
            <router-link to="/alunos" class="btn btn__home">Alunos</router-link>
            <router-link to="/turmas" class="btn btn__home">Turmas</router-link>
            <router-link to="/notas" class="btn btn__home">Notas</router-link>
          </div>
        </div>
      </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import  TheHeader from '@/components/TheHeader.vue';
import  TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'Dashboard',
  components: {
    TheHeader,
    TheFooter
  },
}
</script>
