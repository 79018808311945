<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <HeaderPage title="Turmas" />
      <div class="container">
         <section class="box box__list list">
            <div class="list__row" v-for="turma in turmas" :key="turma.id">
              <strong>{{ turma.title.rendered }}</strong>
              <div class="list__buttons">
                <router-link
                  :to="{ name: 'turma', params: { id: turma.id } }"
                  class="btn btn__solid btn__solid--color-3"
                  >Editar / Alunos</router-link
                >
                <a @click="apagarTurma(turma.id)" class="btn btn__outline btn__outline--color-2">
                  Apagar
                </a>
              </div>
            </div>
          <div>
          </div>
        </section>
        <hr>
        <AdicionarTurma />
      </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import HeaderPage from '@/components/HeaderPage.vue';
import AdicionarTurma from '@/components/AdicionarTurma.vue';

import { mapState, mapActions } from "vuex";
import { api } from "@/services/index.js";

export default {
  name: 'Turmas',
  components: {
    TheHeader,
    TheFooter,
    HeaderPage,
    AdicionarTurma
  },
  computed: {
    ...mapState(["login", "turmas"]),
  },
  methods: {
    ...mapActions(["getTurmas"]),
    apagarTurma(id) {
      const confirmar = window.confirm("Deseja remover esta turma?");
      if (confirmar) {
        api
          .deleteLMS(`/groups/${id}`)
          .then(() => {
            this.getTurmas();
          })
          .catch((error) => {
            console.log(error.reponse);
          });
      }
    },
  },
  watch: {
    login() {
      this.getTurmas();
    },
  },
  created() {
    if (this.login) {
      this.getTurmas();
    }
  },
}
</script>
