<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <HeaderPage title="Alunos" />
      <div class="flex">
        <div class="container">

        <div class="form">
          <div class="field">
            <label for="grupo">Selecione a Turma:</label>
            <select id="grupo" v-model="turmaAlunos.idTurma">
              <option disabled value="">Escolha uma turma</option>
              <option v-for="turma in turmas" :key="turma.id" :value="turma.id">
                {{ turma.title.rendered }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="form" v-for="(aluno, index) in alunos" :key="index">

          <div class="field">
            <label for="nome">Nome do Aluno</label>
            <input id="nome" type="text" v-model="aluno.name" />
          </div>
          <div class="field">
            <label for="email">E-mail</label>
            <input id="email" type="email" v-model="aluno.email" />
          </div>
          <div class="field">
            <label for="senha">Senha</label>
            <input id="senha" type="text" v-model="aluno.senha" disabled />
          </div>
          <div>
          </div>
        </div>
        <div class="actions">
          <a v-on:click="removeElement(index)" class="btn btn__solid btn__solid--color-3">Remover</a>
          <button class="btn btn__solid btn__solid--color-2" @click="addAluno">Adicionar </button>
        </div>
        <hr />

        <button class="btn btn__solid btn__solid--color-5" @click="addTurmas">Cadastrar</button>

        <hr />
        <div v-if="mensagem" class="alert on">{{ mensagem }}</div>

        </div>
      </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import HeaderPage from '@/components/HeaderPage.vue';

import { mapState, mapActions } from "vuex";
import { api } from "@/services/index.js";


export default {
  name: 'Alunos',
  components: {
    TheHeader,
    TheFooter,
    HeaderPage
  },
  data() {
    return {
      turma: null,
      mensagem: null,
      firstPass: this.generate(),
      data: new Date(),
      idAlunos: {
        user_ids: [],
      },
      alunos: [
        {
          name: "",
          email: "",
          senha: this.firstPass,
        },
      ],
      turmaAlunos: {
        idTurma: "",
        alunosTurma: null,
      },
    };
  },
  computed: {
    ...mapState(["turmas"])
  },

  methods: {
    ...mapActions(["getTurmas", "getUsers"]),
    addAluno() {
      this.generate();
      this.alunos.push({
        name: "",
        email: "",
        senha: this.password,
      });
    },
    addTurmas() {
      this.turmaAlunos.alunosTurma = this.alunos;
      api.post("/listaEmail", this.turmaAlunos).then((response) => {
        if (response.status === 200) this.registerUsers();
      });
    },
    registerUsers() {
      this.alunos.forEach((aluno) => {
        api.getUsers(`/wp/v2/users?search=${aluno.email}`).then((response) => {
          const user = response.data;
          this.idAlunos.user_ids.push(user[0].id);
          console.log(this.idAlunos);
          this.enviaGrupo();
        });
      });
    },
    enviaGrupo() {
      api
        .postLMS(`/groups/${this.turmaAlunos.idTurma}/users`, this.idAlunos)
        .then(() => {
          this.mensagem = "Cadastro realizado com sucesso!";

        });
    },
    removeElement(index) {
      this.alunos.splice(index, 1);
    },
    generate() {
      let charactersArray = "a-z,A-Z,0-9,#";
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < 10; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
      return (this.firstPass = password);
    }
  },
  created() {
    this.getTurmas();
    this.generate();
  },
}
</script>


