<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <HeaderPage title="Lançamento de Notas" />
        <div class="container">
          <h2>Aluno: <span>{{ name }}</span></h2>
        <div class="box">
          <div class="form" v-for="(lesson, index) in notas.disciplinas" :key="index">
            <h2 class="title text--color-0">{{ lesson.disciplina}} - Status:<span>{{ lesson.status }}</span> </h2>
            <div class="row-fields">
              <div class="field__checkbox">
                <input type="checkbox" id="checkbox" v-model="lesson.unica" @change="unicaNota($event, index)">
                <label for="checkbox">Nota única?</label>
              </div>
            </div>
            <h3 class="title text--color-3">Notas</h3>
            <div class="row-fields">
              <div class="field">
                <label for="nota1">Nota 1</label>
                <input id="nota1" type="number" min="0" max="10" v-model="lesson.notas.nota0" @change:input="mediaNotas($event, index)"/>
              </div>
              <div class="field">
                <label for="nota2">Nota 2</label>
                <input id="nota2" type="number" min="0" max="10" v-model="lesson.notas.nota1"  @change="mediaNotas($event, index)" :disabled="lesson.unica ? true : false"/>
              </div>
              <div class="field">
                <label for="media">Média</label>
                <input id="media" type="number" min="0" max="10" disabled v-model="lesson.media" />
              </div>
              <div class="field">
                <label for="recup1">Nota Recuperação</label>
                <input id="recup1" type="number" min="0" max="10" v-model="lesson.recuperacao.nota0" @change="mediaNotas($event, index)"/>
              </div>
              <!--<div class="field">
                <label for="media">Média Recuperação</label>
                <input id="media" type="number" min="0" max="10" disabled v-model="lesson.mediaRecuperacao" />
              </div>-->
            </div>
          </div>
        </div>
        <hr />
        <button class="btn btn__solid btn__solid--color-5" @click="addNotas">Enviar</button>
        <div v-if="mensagem" class="mensagem">{{ mensagem }}</div>
        <!--<code>
          <pre>
            {{ notas }}
          </pre>
        </code>-->
        </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import HeaderPage from '@/components/HeaderPage.vue';

import { mapState, mapActions, mapGetters } from "vuex";
import { api } from "@/services/index.js";



export default {
  name: 'Nota',
  props: ["id","name", "idTurma", "idCurso"],
  components: {
    TheHeader,
    TheFooter,
    HeaderPage
  },
  data() {
    return {
      notas: {
        idAluno: this.id,
        disciplinas: []
      },
      lessons: null,
      mensagem: null,
      data: new Date(),
    };
  },
  computed: {
    ...mapState(["turmas"]),
  },
  methods: {
    ...mapActions(["getTurmas"]),
    getNotas() {
      let notas = false;
      api.get(`/notas/${this.id}`).then((response) => {
        if (response.data.notas != null) {
         const disciplinas = response.data.notas;
         disciplinas.forEach(disciplina => {
            if (disciplina.notas.nota2 !== undefined) {
              delete disciplina.notas.nota2;
            }
            if (disciplina.notas.nota3 !== undefined) {
              delete disciplina.notas.nota3;
            }
            if (disciplina.recuperacao.nota1 !== undefined) {
              delete disciplina.recuperacao.nota1;
              disciplina.mediaRecuperacao = 0;
            }
            disciplina.unica = false;
          });
         this.notas.disciplinas = disciplinas;

          notas = true;
        }
          this.getLessons(notas);
      });
    },
    getLessons(notas) {
      api.get('/disciplinas').then((response) => {
        this.lessons = response.data;
        this.createNotas(notas);
      });
    },
    addNotas() {
      api.put('/notas', this.notas).then((response) => {
         if (response.status === 200) this.mensagem = "Notas registradas com sucesso!";

      });
    },
    createNotas(notas) {
      if(notas){
        let lessons = this.lessons.map( lesson => lesson.title);
        let disciplinas = this.notas.disciplinas.map( disciplina => disciplina.disciplina);
        let newLessons = lessons.filter( lesson => disciplinas.indexOf(lesson) === -1);
        newLessons.forEach((lesson) => {
          this.notas.disciplinas.push({
            disciplina: lesson,
            notas: {
              nota0: 0,
              nota1: 0,
            },
            recuperacao: {
              nota0: 0,
            },
            media: 0,
            unica: false,
            status: "",
          });
        });

      }else{
        this.lessons.forEach((lesson) => {
          this.notas.disciplinas.push({
            disciplina: lesson.title,
            notas: {
              nota0: 0,
              nota1: 0,
            },
            recuperacao: {
              nota0: 0,
            },
            media: 0,
            unica: false,
            status: "",
          });
        });

      }

    },
    unicaNota: function (event, index) {
      let notas = this.notas.disciplinas[index].notas;
      notas.nota1 = 0;
      this.mediaNotas(event, index);

    },
    mediaNotas: function (event, index) {
      let notas = this.notas.disciplinas[index].notas;
      let media = 0;
      let recuperacao = 0;
      let unica = this.notas.disciplinas[index].unica;
      let notaRecuperacao = this.notas.disciplinas[index].recuperacao.nota0;
      let status = "";

      const sum = obj => Object.values(obj).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

      let notasValidas = Object.values(notas).filter(function (nota) {
        parseFloat(nota);
        return nota;
      });


      // let notasRecuperacaoValidas = Object.values(notasRecuperacao).filter(function (nota) {
      //   return nota != 0;
      // });
      let count = notasValidas.length;

      // let countRecuperacao = notasRecuperacaoValidas.length;

      if (!unica) {
        media = parseFloat(sum(notasValidas) / count).toFixed(1);
        this.notas.disciplinas[index].media = media;
        status = media >= 6 ? "Aprovado" : "Recuperação";
        // if (media < 6 && countRecuperacao > 0) {
          if (media < 6 && notaRecuperacao != 0) {
          // let somaNotas = parseFloat(media) + parseFloat(notaRecuperacao);
          // recuperacao = parseFloat(somaNotas / 2).toFixed(1);
          // this.notas.disciplinas[index].mediaRecuperacao = recuperacao;
          status = notaRecuperacao >= 6 ? "Aprovado" : "Reprovado";
        }


      }else{
        media = sum(notasValidas);
        this.notas.disciplinas[index].media = media;
        status = media >= 6 ? "Aprovado" : "Recuperação";

        // if (media < 6 && countRecuperacao > 0) {
          if (media < 6 && notaRecuperacao != 0) {
          let somaNotas = parseFloat(media) + parseFloat(notaRecuperacao);
          recuperacao = parseFloat(somaNotas / 2).toFixed(1);
          this.notas.disciplinas[index].mediaRecuperacao = recuperacao;
          status = recuperacao >= 6 ? "Aprovado" : "Reprovado";
        }

      }
        this.notas.disciplinas[index].status = status;
    }

  },
  created() {
    this.getNotas();
  },
}
</script>
