<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <HeaderPage :title="title" />
      <div class="container">
      <section class="box">
        <form class="form">
          <div class="form__content">
            <div class="field">
              <label>Nome da Turma</label>
              <input id="nome" name="nome" type="text" v-model="turma.title"/>
            </div>
            <button
              class="btn btn__solid btn__solid--color-5"
              @click.prevent="alterarTurma"
            >
              Alterar
            </button>
            <div v-if="mensagem" class="alert on">{{ mensagem }}</div>
          </div>
        </form>
        </section>
        <hr>
        <section class="box box__list list">
          <h2 class="title text--color-2">Alunos</h2>
            <div class="list__row" v-for="(aluno, index) in listaAlunos" :key="index" :value="aluno.id">
              <strong>{{ aluno.name }}</strong>
              <div class="list__buttons">
                <router-link
                  :to="{ name: 'nota', params: { id: aluno.id, idTurma: turma, idCurso: curso } }"
                  class="btn btn__solid btn__solid--color-5"
                  >Cadastrar Notas</router-link
                >
                <!-- <a @click="removerAluno(aluno.id)" class="btn btn__outline btn__outline--color-2">
                  Remover aluno
                </a> -->

              </div>
            </div>
        </section>
      </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import HeaderPage from '@/components/HeaderPage.vue';

import { mapState, mapActions } from "vuex";
import { api } from "@/services/index.js";


export default {
  name: 'Turma',
  props: ["id"],
  components: {
    TheHeader,
    TheFooter,
    HeaderPage
  },
  computed: {
    // ...mapState(["turmas","users", "users2"])
     ...mapState(["turmas"])
  },

  data(){
    return {
      title: "",
      turma: {
        title: ""
      },
        idAlunos: {
        user_ids: [],
      },

      listaAlunos: null,
      curso: null,
      mensagem: "",
    }
  },
  methods: {
    // ...mapActions(["getTurmas", "getUsers", "getUsers2"]),
    ...mapActions(["getTurmas"]),
    getCurso() {
      api.getLMS(`/groups/${this.id}/courses`).then((response) => {
        this.curso = response.data[0];
      });
    },
    getTurma() {
      api.getLMS(`/groups/${this.id}`).then((response) => {
        const infoTurma = response.data;

        this.turma.title = infoTurma.title.rendered;
        this.title = 'Turma: ' + infoTurma.title.rendered;
      });
    },
    alterarTurma() {
      api.postLMS(`/groups/${this.id}`, this.turma).then((result) => {

        this.mensagem = "Turma alterada com sucesso!";
        this.getTurma();
        }).catch((error) => {
          this.mensagem = "Erro ao alterar turma!";

        });
    },
    // removerAluno(aluno) {
    //   let idAlunos = `{"user_ids":[${aluno}]}`;

    //   let parseidAlunos = JSON.parse(idAlunos);

    //   const confirmar = window.confirm("Deseja remover o aluno da turma?");
    //   if (confirmar) {
    //     api
    //       .deleteLMSBody(`/groups/1068/users`, parseidAlunos)
    //       .then(() => {
    //         this.getAlunosTurma();
    //       })
    //       .catch((error) => {
    //         console.log(error.message);
    //       });
    //   }
    // },
    getAlunosTurma() {
      api
        .getLMS(`/groups/${this.id}/users?per_page=100`)
        .then((response) => {
          let resp = response.data;
          let array = [];
          // this.turmaAlunos.alunosTurma = resp;
          // let filterUser  = this.users.filter(function(user){
          //   return resp.filter(function(ids){
          //     return ids == user.id;
          //   }).length != 0
          // });
          // let filterUser2  = this.users2.filter(function(user){
          //   return resp.filter(function(ids){
          //     return ids == user.id;
          //   }).length != 0
          // });
          resp.forEach(element => {
            api.getUsers(`/wp/v2/users/${element}`).then((response) => {
              array.push(response.data);
            });
          });
        this.listaAlunos = array;
        });
    },
  },
   created() {
    this.getTurma();
    // this.getUsers();
    // this.getUsers2();
  },
  beforeMount() {
    this.getAlunosTurma();
    this.getCurso();
  },
}
</script>
