<template>
  <header class="header-page">
    <div class="container">
      <h1 class="title title--headerPage text--color-0">{{ title }}</h1>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderPage',
  props: ['title'],
}
</script>

<style>

</style>
