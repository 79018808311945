<template>
	<section class="login">
		<div class="login__container">
			<div class="login__form">
				<header class="login__header">
					<img src="@/assets/Logo-scienza-animali.svg" alt="Scienza Animali" />
					<h2 class="title color-0">Área do aluno</h2>
				</header>
				<form class="form">
					<div class="form__content">
						<div class="field">
							<label>E-mail</label>
							<input
								type="email"
								name="email"
								id="email"
								v-model="login.email"
							/>
						</div>
						<div class="field">
							<label>Senha</label>
							<input
								type="password"
								name="senha"
								id="senha"
								v-model="login.senha"
							/>
						</div>
						<div class="login__buttom">
							<button
								class="btn btn__solid btn__solid--color-0"
								@click.prevent="logar"
							>
								Entrar
							</button>
						</div>
						<p v-if="showError" id="error">
							Nome de usuário ou senha inválidos
						</p>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      login: {
        email: "",
        senha: "",
      },
      showError: false,
    };
  },
   methods: {
    logar() {
      this.$store.dispatch("logarUsuario", this.login).then(() => {
        this.$store.dispatch("getUsuario");
        this.$router.push({ name: "Dashboard" });
      });
    },
  },
};

</script>
