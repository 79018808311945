<template>
	<div id="app">
		<main>
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
	</div>
</template>

<script>
import { api } from "@/services/index.js";

export default {
  created() {
    if (window.localStorage.token) {
      setTimeout(() => {
        api
          .validateToken()
          .then(() => {
            this.$store.dispatch("getUsuario");
          })
          .catch(error => {
            window.localStorage.removeItem("token");
          });
      }, 1000);
    }
  }
};
</script>
