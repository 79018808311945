import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";

import './styles/main.scss';

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
