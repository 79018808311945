<template>
  <header class="header">
    <input type="checkbox" id="burguer-menu" >
    <label for="burguer-menu">
        <span></span>
    </label>
    <div class="container">
        <div class="logo">
          <a href="/dashboard">
              <img src="@/assets/Logo-scienza-animali.svg" alt="Scienza Animali" />
              <div class="logo__title">
                Área do Aluno
              </div>
          </a>
      </div>
      <nav>
        <div class="menu">
          <ul class="menu-list">
            <li><router-link to="/dashboard">Home</router-link></li>
            <li><router-link to="/alunos">Alunos</router-link></li>
            <li><router-link to="/turmas">Turmas</router-link></li>
            <li><router-link to="/notas">Notas</router-link></li>
            <li><a @click="deslogar" class="exit">Sair</a></li>

          </ul>
        </div>
      </nav>
    </div>

  </header>
</template>

<script>
export default {
  name: "TheHeader",
  methods: {
    deslogar() {
      this.$store.dispatch("deslogarUsuario");
      this.$router.push("/");
    },
  },
}
</script>

<style>

</style>
