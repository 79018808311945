import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Alunos from '../views/Alunos.vue';
import Turmas from '../views/Turmas.vue';
import Turma from '../views/Turma.vue';
import Notas from '../views/Notas.vue';
import Nota from '../views/Nota.vue';
import Recados from '../views/Recados.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
    meta: {
      login: true,
    },
	},
	{
		path: '/alunos',
		name: 'Alunos',
		component: Alunos,
    meta: {
      login: true,
    },
	},
	{
		path: '/turmas',
		name: 'Turmas',
		component: Turmas,
    meta: {
      login: true,
    },
	},
  {
    path: "/turma/:id",
    name: "turma",
    component: Turma,
    props: true,
    meta: {
      login: true,
    },
  },
	{
		path: '/notas',
		name: 'Notas',
		component: Notas,
    meta: {
      login: true,
    },
	},
  {
    path: "/nota/:id",
    name: "nota",
    component: Nota,
    props: true,
    meta: {
      login: true,
    },
  },
	{
		path: '/recados',
		name: 'Recados',
		component: Recados,
    meta: {
      login: true,
    },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.login)) {
    if (!window.localStorage.token) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
