<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <HeaderPage title="Notas" />
      <div class="flex">
        <div class="container">

        <div class="form">
          <div class="field">
            <label for="grupo">Selecione a Turma:</label>
            <select id="grupo" v-model="turma">
              <option value="">Escolha uma turma</option>
              <option v-for="turma in turmas" :key="turma.id" :value="turma.id">
                {{ turma.title.rendered }}
              </option>
            </select>
          </div>
        </div>
        <hr />

        <section v-if="turma" class="box box__list list">
          <h2 class="title">Selecione um aluno</h2>
            <div class="list__row" v-for="(aluno, index) in listaAlunos" :key="index" :value="aluno.id">
              <strong>{{ aluno.name }}</strong>
              <div class="list__buttons">
                <router-link
                  :to="{ name: 'nota', params: { id: aluno.id, name: aluno.name, idTurma: turma, idCurso: curso } }"
                  class="btn btn__solid btn__solid--color-5"
                  >Cadastrar</router-link
                >
              </div>
            </div>
        </section>
        </div>
      </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import HeaderPage from '@/components/HeaderPage.vue';

import { mapState, mapActions } from "vuex";
import { api } from "@/services/index.js";



export default {
  name: 'Notas',
  components: {
    TheHeader,
    TheFooter,
    HeaderPage
  },
  data() {
    return {
      notas: {
        idAluno: null,
        disciplinas: []
      },
      turma: null,
      aluno: null,
      curso: null,
      listaAlunos: null,
      mensagem: null,
      data: new Date(),
      idAlunos: {
        user_ids: [],
      },
    };
  },
  computed: {
    // ...mapState(["turmas","users", "users2"])
     ...mapState(["turmas"])
  },
  methods: {
    // ...mapActions(["getTurmas", "getUsers", "getUsers2"]),
    ...mapActions(["getTurmas"]),
    getCurso() {
      api.getLMS(`/groups/${this.turma}/courses`).then((response) => {
        this.curso = response.data[0];
      });
    },
    getAlunosTurma() {
      api
        .getLMS(`/groups/${this.turma}/users?per_page=100`)
        .then((response) => {
          let resp = response.data;
          let array = [];
          // this.turmaAlunos.alunosTurma = resp;
          // let filterUser  = this.users.filter(function(user){
          //   return resp.filter(function(ids){
          //     return ids == user.id;
          //   }).length != 0
          // });
          // let filterUser2  = this.users2.filter(function(user){
          //   return resp.filter(function(ids){
          //     return ids == user.id;
          //   }).length != 0
          // });
          resp.forEach(element => {
            api.getUsers(`/wp/v2/users/${element}`).then((response) => {
              array.push(response.data);
            });
          });
        this.listaAlunos = array;
        });
    },
  },
  watch:{
    turma: function () {
      this.getAlunosTurma();
      this.getCurso();
    }
  },
  created() {
    this.getTurmas();
    // this.getUsers();
  },
}
</script>
