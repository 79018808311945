<template>
  <transition appear>
  <section class="main">
      <TheHeader />
      <HeaderPage title="Recados" />
      <div class="flex">
        Content
      </div>
      <TheFooter />
    </section>
  </transition>
</template>

<script>

import  TheHeader from '@/components/TheHeader.vue';
import  TheFooter from '@/components/TheFooter.vue';
import HeaderPage from '@/components/HeaderPage.vue';

export default {
  name: 'Recados',
  components: {
    TheHeader,
    TheFooter,
    HeaderPage
  },
}
</script>
