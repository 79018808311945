<template>
  <transition appear>
  <section class="box">
    <form class="form">
      <div class="form__content">
        <div class="field">
          <label>Nome da Turma</label>
          <input id="nome" name="nome" type="text" v-model="turma.title" />
        </div>
        <div class="field">
          <label>Curso</label>
          <select id="cursos" name="cursos" v-model="cursoTurma.course_ids">
            <option
              v-for="(curso, index) in cursos"
              :key="index"
              v-bind:value="curso.id"
            >
              {{ nomeCurso(curso.id).title.rendered }}
            </option>
          </select>
        </div>
          <button
            class="btn btn__solid btn__solid--color-5"
            @click.prevent="adicionarTurmas"
          >
            Cadastrar
          </button>
          <div v-if="mensagem" class="alert">{{ mensagem }}</div>
      </div>
    </form>
    </section>
  </transition>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import { api } from "@/services/index.js";

export default {
  name: 'AdicionarTurma',
  computed: {
    ...mapState(["cursos"]),
    ...mapGetters(["nomeCurso"]),
  },
  data() {
    return {
      dados: null,
      mensagem: null,
      cursoTurma: {
        course_ids: [],
      },
      turma: {
        title: "",
        status: "publish",
      },
    };
  },
  methods: {
    ...mapActions(["getListaCursos","getTurmas"]),
    adicionarTurmas() {
      api.postLMS("/groups", this.turma).then((result) => {
        this.$store.dispatch("getTurmas");
        const id = result.data.id;
        this.adicionarCurso(id, this.cursoTurma);
      });
    },
    adicionarCurso(id, idCurso) {
      api
        .postLMS(`/groups/${id}/courses`, idCurso)
        .then(() => {
          this.turma.title = "";
          this.mensagem = "Turma Criada com sucesso";
          this.getTurmas();
        });
    },
  },
  created() {
    this.getListaCursos();
  },
}
</script>


