<template>
  <footer class="footer">
    <p>
      Todos os direitos Reservados © {{ year }}. Desenvolvido por
      <a href="http://www.codeduck.com.br" target="_blank">codeduck</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
    year: new Date().getFullYear()
    }
  },
};
</script>
