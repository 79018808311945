import axios from "axios";

// const endpoint = "http://localhost:8888/";
//const endpoint = "https://homolog.scienzaanimali.com.br/";
const endpoint = "https://www.scienzaanimali.com.br/";

const axiosWP = axios.create({
  baseURL: endpoint + "wp-json",
});
const axiosInstance = axios.create({
  baseURL: endpoint + "wp-json/api",
});

axiosInstance.interceptors.request.use(
  function(config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
axiosWP.interceptors.request.use(
  function(config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
      config.headers.expos;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const axiosLMS = axios.create({
  baseURL: endpoint + "wp-json/ldlms/v1",
});

axiosLMS.interceptors.request.use(
  function(config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const api = {
  getUsers(endpoint) {
    return axiosWP.get(endpoint);
  },
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  getLMS(endpoint) {
    return axiosLMS.get(endpoint);
  },
  postLMS(endpoint, body) {
    return axiosLMS.post(endpoint, body);
  },
  deleteLMS(endpoint) {
    return axiosLMS.delete(endpoint);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  login(body) {
    return axios.post(endpoint + "wp-json/jwt-auth/v1/token", body);
  },
  validateToken() {
    return axios.post(endpoint + "wp-json/jwt-auth/v1/token/validate");
  },
};
