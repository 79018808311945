import Vue from 'vue';
import Vuex from 'vuex';
import { api } from "@/services/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
	state: {
    login: false,
    usuario: {
      id: "",
      nome: "",
      email: "",
      senha: "",
      funcao: "",
    },
    keySG: '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',
    cursos: null,
    turmas: null,
    users: null,
    users2: null,
	},
	mutations: {
    UPDATE_LOGIN(state, payload) {
      state.login = payload;
    },
    UPDATE_USUARIO(state, payload) {
      state.usuario = Object.assign(state.usuario, payload);
    },
    UPDATE_CURSOS(state, payload) {
      state.cursos = payload;
    },
    UPDATE_TURMAS(state, payload) {
      state.turmas = payload;
    },
    UPDATE_USERS(state, payload) {
      state.users = payload;
    },
    UPDATE_USERS2(state, payload) {
      state.users2 = payload;
    },
    ADD_TURMAS(state, payload) {
      state.turmas.unshit(payload);
    },
	},
  getters: {
    nomeCurso: (state) => (idCurso) => {
      return state.cursos.find((curso) => curso.id === idCurso);
    },
    nomeTurma: (state) => (idTurma) => {
      return state.turma.find((turma) => turma.id === idTurma);
    },
    nomeAluno: (state) => (idUser) => {
      return state.users.find((user) => user.id === idUser);
    },
  },
	actions: {
    getUsuario(context) {
      return api.get(`/usuario`).then((response) => {
        context.commit("UPDATE_USUARIO", response.data);
        context.commit("UPDATE_LOGIN", true);
      });
    },
    getListaCursos(context) {
      api.getLMS(`/sfwd-courses`).then((response) => {
        context.commit("UPDATE_CURSOS", response.data);
      });
    },
    getTurmas(context) {
      api.getLMS(`/groups/?per_page=50`).then((response) => {
        context.commit("UPDATE_TURMAS", response.data);
      });
    },
    getUsers(context) {
      api.getUsers(`/wp/v2/users/?per_page=100`).then((response) => {
        context.commit("UPDATE_USERS", response.data);
      });
    },
    getUsers2(context) {
      api.getUsers(`/wp/v2/users/?per_page=100&page=2`).then((response) => {
        context.commit("UPDATE_USERS2", response.data);
      });
    },
    logarUsuario(context, payload) {
      return api
        .login({
          username: payload.email,
          password: payload.senha,
        })
        .then((response) => {
          window.localStorage.token = `Bearer ${response.data.token}`;
          context.commit("UPDATE_LOGIN", true);
        });
    },
    deslogarUsuario(context) {
      context.commit("UPDATE_USUARIO", {
        id: "",
        nome: "",
        email: "",
        senha: "",
        funcao: "",
      });
      window.localStorage.removeItem("token");
      context.commit("UPDATE_LOGIN", false);
    },
	},
});
